// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "T_jM";
export var marketplaceAppDevCaseSection = "T_jT";
export var marketplaceAppDevClientQuotes = "T_jW";
export var marketplaceAppDevIndustriesSection = "T_jL";
export var marketplaceAppDevOnlineSection = "T_jQ";
export var marketplaceAppDevPracticesSection = "T_jR";
export var marketplaceAppDevPrimeSection = "T_jJ";
export var marketplaceAppDevProcessSection = "T_jN";
export var marketplaceAppDevProjLogoSection = "T_jS";
export var marketplaceAppDevServicesSection = "T_jK";
export var marketplaceAppDevTechSection = "T_jP";
export var marketplaceAppExpertiseSection = "T_jV";